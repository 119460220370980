import React from 'react'
import { Jumbotron } from 'reactstrap'
import { Link } from '@reach/router';
import Layout from '../components/Layout'
import DeadlyByteLogo from '../images/deadly-byte-symbol.svg'

const NotFound = () => (
  <Layout>
    <Jumbotron className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-md-center">
      <img className="logo align-self-md-start" alt="Skull shaped as a usb key, with teeth as the connection points - Deadly Byte logo" src={DeadlyByteLogo} />
      <div className="intro ml-md-5 text-center text-md-left mr-md-5">
        <h1 className="display-4">Error 404</h1>
        <p>
          Ooops, something went wrong.
          Let's find a better a place for you to go.
        </p>
        <Link to="/" className="btn btn-outline-primary">Go back to our Home</Link>
      </div>
    </Jumbotron>
    <section id="useful-links" className="container-fluid">
      <div className="container">
        <header>
          <h2>Useful Links</h2>
          <p>Here are some helpful links instead:</p>
        </header>
        <ul className="list-unstyled ml-0">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/#what-we-do">What We Do</Link>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
)

export default NotFound
